import instance from "./api";
import {AxiosResponse} from 'axios';
import {IDriver} from "../helpers/types";


export default class BalanceDriversService {
    static async findAll(sort?:string,limit?:number,page?:number): Promise<AxiosResponse<any>> {
        return instance.get<IDriver[]>('balances/',{
            params: {
                sort,
                limit,
                page,
            }
        } )
    }

    static async createTransaction(participant:any): Promise<AxiosResponse<IDriver>> {
        return instance.post<IDriver>(`balances/create-transaction`,participant)
    }


    static async deleteTransaction(participantID:number): Promise<AxiosResponse<IDriver>> {
        return instance.delete<IDriver>(`balances/remove-transaction/${participantID}`)
    }


    static async getGlobalPercent(): Promise<AxiosResponse<any>> {
        return instance.get<any>(`balances/global-percent`)
    }

    static async saveGlobalPercent(participant:any): Promise<AxiosResponse<any>> {
        return instance.post<any>(`balances/global-percent`,participant)
    }

    static async saveDriverPercent(participant:any): Promise<AxiosResponse<any>> {
        return instance.post<any>(`balances/driver-percent`,participant)
    }

}