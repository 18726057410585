import React, {FC, useEffect} from 'react';
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";

import Preloader from "../molecules/Preloader/Preloader";
import ParticipantsList from "../molecules/ParticipantsList/ParticipantsList";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import {
    asyncDeleteDriverAction, asyncGetBalanceByDateAction,
    asyncGetDriversAction, asyncGetDriversNumbersAction,
    asyncUpdateDriverAction
} from "../../redux/slices/drivers/driversAction";
import {
    changeEditModeDrivers,
    setCurrentPageDrivers, setDates,
    setSelectedCount,
    setSelectedDrivers
} from "../../redux/slices/drivers/driversSlice";
import {getDateRange} from "../../helpers/functions";
import {toast} from "react-toastify";
import {tostSetting} from "../../helpers/scripts";

const DriversContainer:FC = () => {
    const {editMode,currentDate,isLoading, drivers,totalDrivers,currentPage} = useSelectorEx(state => state.drivers);
    const { pageNumber,category } = useParams();
    const dispatch = useDispatchEx();

    useEffect(()=>{
        const newPageNumber = pageNumber ? pageNumber: 1;
        dispatch(asyncGetDriversAction({
            page: newPageNumber,
        }));
        dispatch(setCurrentPageDrivers(Number(newPageNumber)));
        dispatch(changeEditModeDrivers(true));

        const {dateFrom,dateTo} = getDateRange('yesterday');
        dispatch(setDates({
            dateFrom: dateFrom,
            dateTo: dateTo
        }));
      //  dispatch(setSelectedCount(0))
      //  localStorage.removeItem('selectedEmail')
    },[pageNumber,category]);

    const onPagination = (currentPage:number) =>{

           dispatch(asyncGetDriversAction({
               page: currentPage,
           }));
        dispatch(setCurrentPageDrivers(Number(currentPage)));
    }

    const onCheckSelect = (email:string) =>{
        const oldItems:string = localStorage.getItem('selectedEmail') ?? '';
        let oldArraySelected:string[] = oldItems ? oldItems?.split(',') : [];
        if(oldArraySelected?.includes(email)){
            oldArraySelected = oldArraySelected.filter(item => item !== email)
        }
        else{
            oldArraySelected.push(email)
        }
        const newSelected = oldArraySelected.join(',');
        localStorage.setItem('selectedEmail', newSelected)
        dispatch(setSelectedCount(oldArraySelected.length))
        dispatch(setSelectedDrivers(oldArraySelected))
    }

    const onDelete = (id:number) =>{
        dispatch(asyncDeleteDriverAction(id))
    }

    const onSave = (id:number,values:any) =>{
        dispatch(asyncUpdateDriverAction({
            id,
            values
        }))
    }
    const {numbersList,dates,isToday} = useSelectorEx(state => state.drivers)
    const onShare = (userID:string)=>{

        dispatch(asyncGetBalanceByDateAction({
            userID,
            dateFrom:dates?.dateFrom,
            dateTo:dates?.dateTo,
            today: isToday
        }))

        toast.success("Отправлено в телеграм!",tostSetting);
    }

    useEffect(() => {
        dispatch(asyncGetDriversNumbersAction())
    }, []);

    if(isLoading) return <Preloader/>;
    return (
        <ParticipantsList
            numbersList={numbersList?.length ? numbersList : []}
            onSave={onSave}
            edit={editMode}
            currentDate={currentDate}
            isDelete={true}
            onDelete={onDelete}
            onCheckSelect={onCheckSelect}
            items={drivers}
            totalCount={totalDrivers}
            onChangePagination={onPagination}
            currentPage={currentPage}
            onShare={onShare}
            pageUrl={`/admin/drivers${category ? `/category/${category}`: ''}`}
        />
    );
};

export default DriversContainer;