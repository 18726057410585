import './BalanceItem.scss';
import React, {ChangeEvent, FC, ReactNode, useEffect, useState} from 'react';
import Avatar from "../../atoms/Avatar/Avatar";

import {useFormik} from "formik";
import RectButton from "../../atoms/RectButton/RectButton";
import InputEmpty from "../InputEmpty/InputEmpty";
import InputGradient from "../InputGradient/InputGradient";
import {
    changeCurrentUserID,
    changeDateModal,
    changeDriverModalPercent
} from "../../../redux/slices/balanceDrivers/balanceDriversSlice";
import {useDispatchEx, useSelectorEx} from "../../../hooks/redux";
import {getTimeDate} from "../../../helpers/functions";


interface IParticipantItem{
    isCheck?:boolean;
    id?:string;
    userName?: string;
    onDelete?: ()=>void
    onSave?: (values:any)=>void;
    onShare?: ()=>void;
    edit?: boolean;
    balanceDriver?:string;
    userID?:string;
    transactions?:any[]
}

const BalanceItem:FC<IParticipantItem> = ({
                                                  id,
                                                  userName='',
                                                  transactions=[],
                                                  onDelete=()=>{},
                                                    userID,
                                                  onSave=()=>{},
                                                  edit=false,
                                                  balanceDriver=0,
                                              }) => {
    const [open, setOpen] = useState<boolean>(false)

    const {errors,touched,values,handleChange,handleBlur,handleSubmit,setFieldValue,resetForm} = useFormik({
        initialValues: {
            comment: '',
            amount:""
        },
        enableReinitialize: true,
        validationSchema:null,
        onSubmit: (value) => formHandle(value),
    });
    const formHandle = (values: any)=>{
       onSave(values);
        resetForm();
    };
    const changeOpen = ()=>{
        setOpen(!open)
    }


    const dispatch = useDispatchEx();

    const { selectDate } = useSelectorEx(state => state.balanceDrivers);

    const openModalDriverPercent = ()=>{
        dispatch(changeDriverModalPercent(true));
        dispatch(changeCurrentUserID(userID))
    }


    const totalTransactions = (transactions.reduce((acc, transaction) => Number(acc) + Number(transaction.amount), 0)).toFixed(2);

    const balance = (balanceDriver ? Number(balanceDriver).toFixed(2) : 0)

    const totalBalance = Number(balance) + Number(totalTransactions);

    return (
        <div className={`balance-item ${open ? "active": ""}`} >
            <div  className="balance-item__wrapper">
{/*                {isCheck && <div className="participant-item__select">
                    <Checkbox checked={isSelected} onChange={onSelect}/>
                </div>}*/}
                <div className={"balance-item__inner"} onClick={changeOpen}>
                 {/*   <div className="participant-item__id">ID:{id}</div>*/}
                    <div className="participant-item__avatar"><Avatar name={userName} /></div>
                    <div className="participant-item__user-name">
                        {userName}
                    </div>
                    <div className="participant-item__money"> {totalBalance.toFixed(2) } грн.</div>
                {/*    {!edit ?
                        <div className="participant-item__money"> <RectButton onClick={onShare} name={'Поделиться'} /> </div>
                        : ""}*/}

                    {edit ?
                        <div className="participant-item__money"><RectButton onClick={()=>handleSubmit()} name={'Обновить'} /></div>
                        : ""}


                </div>

            </div>

            <div className="balance-item__content">
                <div className="balance-item__content-wrapper">

                    <div className="balance-item__content-list">
                        <div className="balance-item__content-list-header">
                            Транзакции водителя
                        </div>
                        <div className="balance-item__content-form">
                            <div className="balance-item__content-input">
                                <InputGradient onChange={handleChange} onBlur={handleBlur} value={values.comment} ErrorMessage={errors.comment} name={'comment'} placeholder={'Коментарий'} />
                            </div>
                            <div className="balance-item__content-input">
                                <InputGradient onChange={handleChange} onBlur={handleBlur} value={values.amount} ErrorMessage={errors.amount} name={'amount'} placeholder={'Кол-во'} />
                            </div>
                            <div className="balance-item__content-button">
                                <RectButton onClick={()=>handleSubmit()} name={'Создать'} />
                                <RectButton type={'htmlLink'} onClick={()=>dispatch(changeDateModal(true))}  className={'btn-rect-gradient-long'} name={'Выбрать дату'} />
                                <span>{getTimeDate(selectDate)}</span>
                            </div>
                        </div>

                        <div className="balance-item__content-list-wrapper">

                            <div className={'balance-item__content-list-item'}>
                                {transactions?.length > 0 ?
                                    transactions.map((transaction,index) =>{
                                        return  <div key={index} className={`balance-item__content-item `}>
                                            <span>ID: {transaction.id} {transaction?.comment}</span>
                                            <span className={`${transaction.amount > 0 ? `increase` : `decrease`}`}>{transaction.amount > 0 ? `+${transaction.amount}` : `-${transaction.amount}`} грн.</span>
                                            <span>{getTimeDate(transaction?.date*1000)}</span>
                                        </div>
                                    })

                                    : ""}
                            </div>
                            <div className={'balance-item__content-list-item'}>
                                <RectButton onClick={openModalDriverPercent} name={`Проценты водителя`} />

                            </div>

                          {/*  <div className="balance-item__content-total">
                                <strong>Общая сумма: </strong>
                                грн.
                            </div>*/}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default BalanceItem;