import {createAsyncThunk} from "@reduxjs/toolkit";

import {IDriver} from "../../../helpers/types";
import DriversService from "../../../api/DriversService";
import BalanceDriversService from "../../../api/BalanceDriversService";




export const asyncGetBalanceDriversAction = createAsyncThunk(
    'balanceDrivers/all',
    async (query:any, thunkAPI)=>{
        try {
            const {sort,page,limit} = query;


            let response = await BalanceDriversService.findAll(sort,limit,page);
            return response.data;


        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);


export const asyncBalanceDriverAction = createAsyncThunk(
    'balanceDrivers/createTransaction',
    async (query:any, thunkAPI)=>{
        try {
            const {userID,amount,date,comment} = query;
            let response = await BalanceDriversService.createTransaction({
                userID,
                amount,
                date,
                comment,
            });
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);


export const asyncDeleteBalanceDriverAction = createAsyncThunk(
    'balanceDrivers/delete',
    async (id:number, thunkAPI)=>{
        try {
            let response = await BalanceDriversService.deleteTransaction(id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);


export const asyncSaveGlobalPercentAction = createAsyncThunk(
    'balanceDrivers/saveGlobalPercent',
    async (query:any, thunkAPI)=>{
        try {
            let response = await BalanceDriversService.saveGlobalPercent({
                minFrom: Number(query.minFrom),
                minTo: Number(query.minTo),
                minPercent: Number(query.minPercent),
                maxFrom: Number(query.maxFrom),
                maxPercent: Number(query.maxPercent),
                type: query.type,
                intervals: JSON.stringify(query.intervals)
            });
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);


export const asyncSaveDriverPercentAction = createAsyncThunk(
    'balanceDrivers/saveDriverPercent',
    async (query:any, thunkAPI)=>{
        try {
            let response = await BalanceDriversService.saveDriverPercent({
                userID: query.userID,
                minFrom: Number(query.minFrom),
                minTo: Number(query.minTo),
                minPercent: Number(query.minPercent),
                maxFrom: Number(query.maxFrom),
                maxPercent: Number(query.maxPercent),
                type: query.type,
                intervals: JSON.stringify(query.intervals)
            });
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);


export const asyncGetGlobalPercentAction = createAsyncThunk(
    'balanceDrivers/getGlobalPercent',
    async (_, thunkAPI)=>{
        try {
            let response = await BalanceDriversService.getGlobalPercent();
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

//saveGlobalPercent