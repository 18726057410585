import React, { FC, useEffect, useState } from 'react';
import { useDispatchEx, useSelectorEx } from "../../hooks/redux";

import Preloader from "../molecules/Preloader/Preloader";
import BalanceList from "../molecules/BalanceList/BalanceList";
import ModalMini from "../molecules/ModalMini/ModalMini";
import { DayPicker } from "react-day-picker";
import { useParams } from "react-router-dom";

import {
    asyncBalanceDriverAction,
    asyncGetBalanceDriversAction, asyncSaveDriverPercentAction, asyncSaveGlobalPercentAction
} from "../../redux/slices/balanceDrivers/balanceDriversAction";
import {
    changeDateModal,
    changeDriverModalPercent,
    changeSelectDate
} from "../../redux/slices/balanceDrivers/balanceDriversSlice";
import RectButton from "../atoms/RectButton/RectButton";
import InputGradient from "../molecules/InputGradient/InputGradient";
import DeleteIcon from "../atoms/icons/DeleteIcon";
import Select from "../atoms/Select/Select";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import {tostSetting} from "../../helpers/scripts";

type SortField = 'amount' | 'name' | null;
type SortOrder = 'asc' | 'desc';

const BalanceDriversContainer: FC = () => {
    const { balanceDrivers, isLoading, totalBalanceDrivers, isDateModal, selectDate } = useSelectorEx(state => state.balanceDrivers);
    const { pageNumber, category } = useParams();
    const dispatch = useDispatchEx();

    // Состояния для сортировки
    const [sortField, setSortField] = useState<SortField>(null);
    const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
    const [selectedDate, setSelectedDate] = useState<Date>();

    useEffect(() => {
        const newPageNumber = pageNumber ? pageNumber : 1;
        dispatch(asyncGetBalanceDriversAction({
            page: newPageNumber,
        }));
    }, [pageNumber, category]);

    // Устанавливаем текущую дату при монтировании
    useEffect(() => {
        const currentDate = new Date();
        setSelectedDate(currentDate);
        dispatch(changeSelectDate(currentDate.getTime()));
    }, []);

    const onSaveTransaction = (userID: string, values: any) => {
        dispatch(asyncBalanceDriverAction({
            userID: userID,
            amount: Number(values.amount),
            date: selectDate,
            comment: values.comment
        }));
        toast.success("Баланс водителя изменен!",tostSetting);
    };

    const onSetDate = () => {
        if (selectedDate) {
            dispatch(changeSelectDate(selectedDate.getTime()));
            dispatch(changeDateModal(false));
        }
    };

    // Функция сортировки данных
    const sortItems = (items: any[]) => {
        if (!sortField) return items;
        return [...items].sort((a, b) => {
            if (sortField === 'amount') {

                const transactionA =  a.transactions.reduce((acc:any, transaction:any) => Number(acc) + Number(transaction.amount), 0);
                const transactionB =  b.transactions.reduce((acc:any, transaction:any) => Number(acc) + Number(transaction.amount), 0);

                const balanceA = (a?.balance ? Number(a?.balance) : 0) + Number(transactionA);
                const balanceB = (b?.balance ? Number(b?.balance) : 0) + Number(transactionB);
                return sortOrder === 'asc'
                    ? balanceA - balanceB
                    : balanceB - balanceA;
            } else if (sortField === 'name') {
                return sortOrder === 'asc'
                    ? a.name.localeCompare(b.name)
                    : b.name.localeCompare(a.name);
            }
            return 0;
        });
    };

    // Обработчики для кнопок сортировки
    const onSortByName = () => {
        if (sortField === 'name') {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField('name');
            setSortOrder('asc');
        }
    };

    const onSortByAmount = () => {
        if (sortField === 'amount') {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField('amount');
            setSortOrder('asc');
        }
    };
    console.log('balanceDrivers',balanceDrivers)
    const sortedBalanceDrivers = sortItems(balanceDrivers);
    const {globalPercents,driverModalPercent,currentUserID} = useSelectorEx(state => state.balanceDrivers);

    const {handleSubmit,setFieldValue,handleChange,handleBlur,values,resetForm,errors,touched} = useFormik({
        initialValues: {
            minFrom: '',
            minTo: '',
            minPercent: '',
            maxFrom: '',
            type: 'fix',
            maxPercent: '',
            intervals: [
                {
                    from: '',
                    to: '',
                    percent: '',
                },
            ],
        },
        validationSchema: null,
        onSubmit: (values) => sendForm(values),
    });

    const sendForm = (values:any)=>{

        console.log('values',values)
        dispatch(asyncSaveDriverPercentAction({
            userID: currentUserID,
            ...values
        }))
        toast.success("Процент для водителя сохранен!",tostSetting);
    }


    const addRow = () => {
        const newRow ={
            from: '',
            to: '',
            percent: '',
        };
        setFieldValue('intervals', [...values.intervals, newRow]);
    };

    // Удаление строки по индексу
    const removeRow = (index:any) => {
        const updatedIntervals = values.intervals.filter((_, i:any) => i !== index);
        setFieldValue('intervals', updatedIntervals);
    };

    useEffect(() => {
        if(driverModalPercent){

            const currentDriverPercent = balanceDrivers.filter(item=>item.userID === currentUserID)![0];

            const settingsPercent = currentDriverPercent?.percentsSettings ? JSON.parse(currentDriverPercent.percentsSettings) : {}

            setFieldValue('minFrom',settingsPercent.minFrom ?? 0);
            setFieldValue('minTo',settingsPercent.minTo ?? 0);
            setFieldValue('minPercent',settingsPercent.minPercent ?? 0);
            setFieldValue('maxFrom',settingsPercent.maxFrom ?? 0);
            setFieldValue('maxPercent',settingsPercent.maxPercent ?? 0);
            setFieldValue('intervals',settingsPercent.intervals ? JSON.parse(settingsPercent.intervals) : [{
                from: '',
                to: '',
                percent: '',
            }]);
        }
    }, [driverModalPercent]);



    if (isLoading) return <Preloader />;

    return (
        <>
            <ModalMini active={driverModalPercent} onAccept={()=>{}} onCancel={()=>dispatch(changeDriverModalPercent(false))} onClose={()=>dispatch(changeDriverModalPercent(false))} header={'Выбрать процент'}>
                <form onSubmit={handleSubmit} style={{display:'flex',gap:'10px', flexDirection:'column'}}>
                    <div className="modal-mini__row">
                        {/* Блок «От» */}
                        <div className="modal-mini__column">
                            {/*<label htmlFor="fromIncome">От</label>*/}
                            <InputGradient
                                id="minFrom"
                                name="minFrom"
                                placeholder="От"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.minFrom}
                            />
                            {touched.minFrom && errors.minFrom && (
                                <p className="error">{errors.minFrom}</p>
                            )}
                        </div>

                        {/* Блок «До» */}
                        <div className="modal-mini__column">
                            {/*                        <label htmlFor="toIncome">До</label>*/}
                            <InputGradient
                                id="minTo"
                                name="minTo"
                                placeholder="До"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.minTo}
                            />
                            {touched.minTo && errors.minTo && (
                                <p className="error">{errors.minTo}</p>
                            )}
                        </div>

                        {/* Третий инпут (например, «Процент») */}
                        <div className="modal-mini__column">
                            {/*  <label htmlFor="driverPercent">Процент</label>*/}
                            <InputGradient
                                id="minPercent"
                                name="minPercent"
                                placeholder="%"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.minPercent}
                            />
                            {touched.minPercent && errors.minPercent && (
                                <p className="error">{errors.minPercent}</p>
                            )}
                        </div>
                    </div>

                    {values.intervals.map((interval:any, index) => (
                        <div className="modal-mini__row" key={index}>
                            {/* Блок «От» */}
                            <div className="modal-mini__column">
                                {/*    <label htmlFor={`intervals[${index}].from`}>От</label>*/}
                                <InputGradient
                                    id={`intervals[${index}].from`}
                                    name={`intervals[${index}].from`}
                                    placeholder="От"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={interval.from}
                                />

                            </div>

                            {/* Блок «До» */}
                            <div className="modal-mini__column">
                                {/*  <label htmlFor={`intervals[${index}].to`}>До</label>*/}
                                <InputGradient
                                    id={`intervals[${index}].to`}
                                    name={`intervals[${index}].to`}
                                    placeholder="До"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={interval.to}
                                />
                            </div>

                            {/* Блок «Процент» */}
                            <div className="modal-mini__column">
                                {/*    <label htmlFor={`intervals[${index}].percent`}>Процент %</label>*/}
                                <InputGradient
                                    id={`intervals[${index}].percent`}
                                    name={`intervals[${index}].percent`}
                                    placeholder="%"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={interval.percent}
                                />

                            </div>


                            <div className="modal-mini__column">
                                <button className="modal-mini__delete" onClick={() => removeRow(index)}>
                                    <DeleteIcon/>
                                </button>

                            </div>
                            {/* Кнопка удаления строки */}


                        </div>
                    ))}

                    {/* Кнопка добавления новой строки */}
                    <RectButton onClick={addRow} type={'button'} name={'+'} />

                    <div className="modal-mini__row">
                        {/* Блок «От» */}
                        <div className="modal-mini__column">
                            {/* <label htmlFor="maxFrom">От</label>*/}
                            <InputGradient
                                id="maxFrom"
                                name="maxFrom"
                                placeholder="От"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.maxFrom}
                            />
                            {touched.maxFrom && errors.maxFrom && (
                                <p className="error">{errors.maxFrom}</p>
                            )}
                        </div>

                        {/* Блок «До» */}
                        <div className="modal-mini__column" style={{minWidth:'33%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <label htmlFor="toIncome">И выше</label>
                        </div>

                        {/* Третий инпут (например, «Процент») */}
                        <div className="modal-mini__column">
                            {/*<label htmlFor="maxPercent">Процент</label>*/}
                            <InputGradient
                                id="maxPercent"
                                name="maxPercent"
                                placeholder="%"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.maxPercent}
                            />
                            {touched.maxPercent && errors.maxPercent && (
                                <p className="error">{errors.maxPercent}</p>
                            )}
                        </div>
                    </div>
                    <div className="modal-mini__row">
                        <Select
                            defaultValue={values.type}
                            onChange={(value:string)=>{
                                setFieldValue('type',value)
                            }}

                            items={[
                                {
                                    name: "Фіксовані",
                                    value: "fix",
                                },
                                {
                                    name: "Не Фіксовані",
                                    value: "no_fix",
                                }
                            ]} />
                    </div>
                    <div className="modal-mini__input">
                        <RectButton type={'button'} name={'Сохранить'} />
                    </div>
                </form>
            </ModalMini>
            <ModalMini
                active={isDateModal}
                onAccept={onSetDate}
                onCancel={() => dispatch(changeDateModal(false))}
                onClose={() => dispatch(changeDateModal(false))}
                header={'Обрати дату'}
            >
                <DayPicker
                    mode="single"
                    selected={selectedDate}
                    onSelect={setSelectedDate}
                />
            </ModalMini>

            {/* Элементы для управления сортировкой */}
            <div style={{ marginBottom: '1rem',display:'flex',gap: '10px' }}>
                <RectButton onClick={onSortByName} name={`Sort by Name ${sortField === 'name' && sortOrder} `} />
                <RectButton onClick={onSortByAmount} name={`Sort by Balance ${sortField === 'amount' && sortOrder} `} />

            </div>

            <BalanceList
                onSave={onSaveTransaction}
                items={sortedBalanceDrivers}
                totalCount={totalBalanceDrivers}
                onChangePagination={() => { }}
                currentPage={1}
                pageUrl={`/admin/balances`}
            />
        </>
    );
};

export default BalanceDriversContainer;
