import {FC, useCallback, useState} from 'react';
import DriversContainer from "../../components/containers/DriversContainer";
import RectButton from "../../components/atoms/RectButton/RectButton";
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import instance from "../../api/api";
import Select from "../../components/atoms/Select/Select";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import ModalMini from "../../components/molecules/ModalMini/ModalMini";
import {getDateRange, getTimeDate} from "../../helpers/functions";
import {asyncGetDriversAction} from "../../redux/slices/drivers/driversAction";
import {
    changeEditModeDrivers, changeIsToday,
    setCurrentDateDrivers,
    setCurrentPageDrivers, setDates
} from "../../redux/slices/drivers/driversSlice";
const OrdersPage:FC = () => {

    const dispatch = useDispatchEx();
    const handleExport = async () => {
        try {
            // Отправляем запрос на сервер
            const response = await instance.get('/drivers/export', {
                responseType: 'blob', // Указываем тип ответа
            });

            if (response.status !== 200) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }


            const contentType = response.headers['content-type'];
            if (!contentType || !contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                throw new Error('Received incorrect content type');
            }

            const blob = new Blob([response.data], { type: contentType });

            const url = window.URL.createObjectURL(blob);

            const link:any = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'emails.xlsx');
            document.body.appendChild(link);
            link.click();

            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);

        } catch (error) {
        }
    };
    const options = [
        { name: "Сьогодні", value: "today" },
        { name: "Вчора", value: "yesterday" },
        { name: "Поточний тиждень", value: "this_week" },
        { name: "Минулий тиждень", value: "last_week" },
        { name: "Поточний місяць", value: "this_month" },
        { name: "Минулий місяць", value: "last_month" },
        { name: "Поточних півроку", value: "half_year" },
        { name: "Поточний рік", value: "this_year" }
    ];
    const [selectedDate, setSelectedDate] = useState<Date>();
//href={`${process.env.REACT_APP_URL_SERVER}/api/drivers/export`}
    const [openModal, setOpenModal] = useState(false);


    const onChangeDate = (value:string) =>{
        if(value === 'yesterday'){
            dispatch(changeEditModeDrivers(true));
            const {dateFrom,dateTo} = getDateRange(value);
            dispatch(asyncGetDriversAction({
                dateFrom: dateFrom,
                dateTo: dateTo
            }));
            dispatch(setDates({
                dateFrom: dateFrom,
                dateTo: dateTo
            }));
            dispatch(changeIsToday(false))
        }
        else if(value === 'today'){
            dispatch(changeIsToday(true))
            dispatch(changeEditModeDrivers(false));
            dispatch(asyncGetDriversAction({
                today: true
            }));
            dispatch(setDates(null));
        }
        else {
            dispatch(changeEditModeDrivers(false));
            const {dateFrom,dateTo} = getDateRange(value);
            dispatch(asyncGetDriversAction({
                dateFrom: dateFrom,
                dateTo: dateTo
            }));
            dispatch(setDates({
                dateFrom: dateFrom,
                dateTo: dateTo
            }));
            dispatch(changeIsToday(false))
        }
        dispatch(setCurrentDateDrivers(value));


    }

    const onSetDate = () =>{
        const currentFromDate = selectedDate as Date
        const currentFrom = currentFromDate.getTime()/1000;
      //  dispatch(setCurrentDateDrivers(value));
        const nowDate = new Date().setHours(0, 0, 0, 0);
        const oneDay = 24 * 60 * 60 * 1000;
        if(currentFrom >= nowDate/1000){
            dispatch(changeEditModeDrivers(false));
            dispatch(asyncGetDriversAction({
                today: true
            }));
            dispatch(changeIsToday(true))
            dispatch(setDates(null));
        }
        else{
            dispatch(changeEditModeDrivers(true));
            dispatch(asyncGetDriversAction({
                dateFrom: currentFrom,
                dateTo: currentFrom+(oneDay/1000)
            }));
            dispatch(setDates({
                dateFrom: currentFrom,
                dateTo: currentFrom+(oneDay/1000)
            }));
        }
        setOpenModal(false)
    }

    const {dates,isToday} = useSelectorEx(state => state.drivers)

    return (
        <div className={'main-admin__content'}>

            <ModalMini active={openModal} onAccept={onSetDate} onCancel={()=>setOpenModal(false)} onClose={()=>setOpenModal(false)} header={'Обрати дату'}>
                <DayPicker
                    mode="single"
                    selected={selectedDate}
                    onSelect={setSelectedDate}
                    /*footer={
                        selected ? `Selected: ${selected.toLocaleDateString()}` : "Pick a day."
                    }*/
                />
            </ModalMini>

            <div className="main-admin__header">
            <h1 style={{color:"#ffffff",marginBottom:"20px"}}>Список водителей</h1>

            </div>
            <div className="main-admin__filters">
                <div className={'main-admin__buttons'}>
                    <div className="main-admin__select">
                        <Select
                            //    type={'search'}
                              defaultValue={'yesterday'}
                            //       className={errors.categoryId && touched.categoryId ? "error": ""}
                            onChange={onChangeDate}
                            placeholder={"Период"}
                            items={options}
                        />
                    </div>

                    <RectButton type={'htmlLink'} onClick={()=>setOpenModal(true)}  className={'btn-rect-gradient-long'} name={'Выбрать дату'} />
                   <div className="main-admin__date">
                       {dates ?
                           `${getTimeDate(dates?.dateFrom*1000)} - ${getTimeDate(dates?.dateTo*1000) }`

                           : (
                               isToday ? `Сегодня`: "Вчера")
                       }

                   </div>

                    {/* <RectButton type={'htmlLink'} onClick={handleExport}  className={'btn-rect-gradient-long'} name={'Export XLSX'} />*/}
                </div>
            </div>
            <DriversContainer/>
        </div>
    );
};

export default OrdersPage;