import './BalanceList.scss';

import React, {FC} from 'react';
import Placeholder from "../../atoms/Placeholder/Placeholder";
import Pagination from "../../atoms/Pagination/Pagination";
import BalanceItem from "../BalanceItem/BalanceItem";

interface IParticipantsList{
    items: any[];
    onChangePagination?:(value:number)=>void;
    totalCount?:number;
    currentPage?:number;
    perPage?:number;
    pageUrl?:string;
    onSave?: (id:string,values:any)=>void;
    onDelete?: (id:number)=>void
    edit?: boolean;
}

const BalanceList:FC<IParticipantsList> = ({onSave=()=>{},edit=false,onDelete=()=>{},items,onChangePagination=()=>{},totalCount=0,perPage=12,currentPage=1,pageUrl=''}) => {

    return (
        <div className={'participant-list'}>
            <div style={{marginBottom:"20px"}}>
                <div className={`participant-item`} >
                    <div className="participant-item__wrapper">
                        <div className={"participant-item__inner"}>
                       {/*     <div className="participant-item__id">ID:</div>*/}
                            <div className="participant-item__avatar"></div>
                            <div className="participant-item__user-name">Имя водителя</div>
                            <div className="participant-item__money">Баланс водителя</div>
                      {/*      <div className="participant-item__money">Расходы</div>*/}
                            {!edit ?<div className="participant-item__money">Доп.</div> : ""}
                         {/*   <div className="participant-item__date">Дата</div>*/}
                        </div>
                    </div>


                </div>

            </div>

            {items.length > 0 ?
                items.map(item=> {
                    return <div key={item.id} className="participant-list__item"><BalanceItem
                        onDelete={()=>onDelete(item.id)}
                        id={item.id?.toString()}
                        userName={`${item.name}`}
                        balanceDriver={item.balance}
                        transactions={item.transactions}
                        userID={item.userID}
                        onSave={(values)=>onSave(item.userID,values)}
                    /></div>
                })
                : <Placeholder header={'Ups!'} message={'Users not found'} />
            }
            {totalCount > 0 &&
                <div className="participant-list__pagination">
                    <Pagination pageUrl={pageUrl} currentPage={currentPage} totalItems={totalCount} itemsPerPage={perPage} onPageChange={onChangePagination}/>
                </div>
            }
        </div>
    );
};

export default BalanceList;