import React, {FC, useCallback, useEffect, useState} from 'react';
import DriversContainer from "../../components/containers/DriversContainer";
import RectButton from "../../components/atoms/RectButton/RectButton";
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";

import "react-day-picker/style.css";
import ModalMini from "../../components/molecules/ModalMini/ModalMini";

import BalanceDriversContainer from "../../components/containers/BalanceDriversContainer";
import InputEmpty from "../../components/molecules/InputEmpty/InputEmpty";
import {ErrorMessage, FieldArray, FormikErrors, useFormik} from "formik";
import * as yup from "yup";
import InputGradient from "../../components/molecules/InputGradient/InputGradient";
import DeleteIcon from "../../components/atoms/icons/DeleteIcon";
import Select from "../../components/atoms/Select/Select";
import {
    asyncGetGlobalPercentAction,
    asyncSaveGlobalPercentAction
} from "../../redux/slices/balanceDrivers/balanceDriversAction";
import {changeDriverModalPercent} from "../../redux/slices/balanceDrivers/balanceDriversSlice";
import {toast} from "react-toastify";
import {tostSetting} from "../../helpers/scripts";

const PercentFormSchema = yup.object().shape({
    fromIncome: yup.number()
        .min(0, 'Значение не может быть меньше 0')
        .required('Обязательное поле'),
    toIncome: yup.number()
        .min(yup.ref('fromIncome'), 'Не может быть меньше «От»')
        .required('Обязательное поле'),
    driverPercent: yup.number()
        .min(0, 'Процент не может быть отрицательным')
        .max(100, 'Максимальный процент — 100')
        .required('Обязательное поле'),
});


const BalancesPage:FC = () => {

    const dispatch = useDispatchEx();
    const [openModalPercent, setOpenModalPercent] = useState(false);
    const [openModalGlobalPercent, setOpenModalGlobalPercent] = useState(false);
    const changePercentage = () => {
        setOpenModalPercent(true)
    }


    const {handleSubmit,setFieldValue,handleChange,handleBlur,values,resetForm,errors,touched} = useFormik({
        initialValues: {
            minFrom: '',
            minTo: '',
            minPercent: '',
            maxFrom: '',
            type: 'fix',
            maxPercent: '',
            intervals: [
                {
                    from: '',
                    to: '',
                    percent: '',
                },
            ],
        },
        validationSchema: null,
        onSubmit: (values) => sendForm(values),
    });

    const sendForm = (values:any)=>{

        console.log('values',values)
        dispatch(asyncSaveGlobalPercentAction(values));

        toast.success("Глобальный процент был изменен!",tostSetting);
    }

    useEffect(() => {
        dispatch(asyncGetGlobalPercentAction())
    }, []);


    // Добавление новой строки
    const addRow = () => {
        const newRow ={
            from: '',
            to: '',
            percent: '',
        };
        setFieldValue('intervals', [...values.intervals, newRow]);
    };

    // Удаление строки по индексу
    const removeRow = (index:any) => {
        const updatedIntervals = values.intervals.filter((_, i:any) => i !== index);
        setFieldValue('intervals', updatedIntervals);
    };


    const {globalPercents} = useSelectorEx(state => state.balanceDrivers);

    useEffect(() => {
        if(globalPercents){
            setFieldValue('minFrom',globalPercents.minFrom);
            setFieldValue('minTo',globalPercents.minTo);
            setFieldValue('minPercent',globalPercents.minPercent);
            setFieldValue('maxFrom',globalPercents.maxFrom);
            setFieldValue('maxPercent',globalPercents.maxPercent);
            setFieldValue('intervals',JSON.parse(globalPercents.intervals));
        }

    }, [globalPercents]);

    return (
        <div className={'main-admin__content'}>
            <ModalMini active={openModalPercent} onAccept={()=>{}} onCancel={()=>setOpenModalPercent(false)} onClose={()=>setOpenModalPercent(false)} header={'Выбрать процент'}>
                <form onSubmit={handleSubmit} style={{display:'flex',gap:'10px', flexDirection:'column'}}>
                <div className="modal-mini__row">
                    {/* Блок «От» */}
                    <div className="modal-mini__column">
                        {/*<label htmlFor="fromIncome">От</label>*/}
                        <InputGradient
                            id="minFrom"
                            name="minFrom"
                            placeholder="От"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.minFrom}
                        />
                        {touched.minFrom && errors.minFrom && (
                            <p className="error">{errors.minFrom}</p>
                        )}
                    </div>

                    {/* Блок «До» */}
                    <div className="modal-mini__column">
{/*                        <label htmlFor="toIncome">До</label>*/}
                        <InputGradient
                            id="minTo"
                            name="minTo"
                            placeholder="До"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.minTo}
                        />
                        {touched.minTo && errors.minTo && (
                            <p className="error">{errors.minTo}</p>
                        )}
                    </div>

                    {/* Третий инпут (например, «Процент») */}
                    <div className="modal-mini__column">
                      {/*  <label htmlFor="driverPercent">Процент</label>*/}
                        <InputGradient
                            id="minPercent"
                            name="minPercent"
                            placeholder="%"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.minPercent}
                        />
                        {touched.minPercent && errors.minPercent && (
                            <p className="error">{errors.minPercent}</p>
                        )}
                    </div>
                </div>

                    {values.intervals.map((interval:any, index) => (
                        <div className="modal-mini__row" key={index}>
                            {/* Блок «От» */}
                            <div className="modal-mini__column">
                            {/*    <label htmlFor={`intervals[${index}].from`}>От</label>*/}
                                <InputGradient
                                    id={`intervals[${index}].from`}
                                    name={`intervals[${index}].from`}
                                    placeholder="От"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={interval.from}
                                />

                            </div>

                            {/* Блок «До» */}
                            <div className="modal-mini__column">
                              {/*  <label htmlFor={`intervals[${index}].to`}>До</label>*/}
                                <InputGradient
                                    id={`intervals[${index}].to`}
                                    name={`intervals[${index}].to`}
                                    placeholder="До"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={interval.to}
                                />
                            </div>

                            {/* Блок «Процент» */}
                            <div className="modal-mini__column">
                            {/*    <label htmlFor={`intervals[${index}].percent`}>Процент %</label>*/}
                                <InputGradient
                                    id={`intervals[${index}].percent`}
                                    name={`intervals[${index}].percent`}
                                    placeholder="%"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={interval.percent}
                                />
    
                            </div>


                            <div className="modal-mini__column">
                                <button className="modal-mini__delete" onClick={() => removeRow(index)}>
                                    <DeleteIcon/>
                                </button>

                            </div>
                            {/* Кнопка удаления строки */}


                        </div>
                    ))}

                    {/* Кнопка добавления новой строки */}
                    <RectButton onClick={addRow} type={'button'} name={'+'} />

                    <div className="modal-mini__row">
                        {/* Блок «От» */}
                        <div className="modal-mini__column">
                           {/* <label htmlFor="maxFrom">От</label>*/}
                            <InputGradient
                                id="maxFrom"
                                name="maxFrom"
                                placeholder="От"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.maxFrom}
                            />
                            {touched.maxFrom && errors.maxFrom && (
                                <p className="error">{errors.maxFrom}</p>
                            )}
                        </div>

                        {/* Блок «До» */}
                        <div className="modal-mini__column" style={{minWidth:'33%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <label htmlFor="toIncome">И выше</label>
                        </div>

                        {/* Третий инпут (например, «Процент») */}
                        <div className="modal-mini__column">
                            {/*<label htmlFor="maxPercent">Процент</label>*/}
                            <InputGradient
                                id="maxPercent"
                                name="maxPercent"
                                placeholder="%"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.maxPercent}
                            />
                            {touched.maxPercent && errors.maxPercent && (
                                <p className="error">{errors.maxPercent}</p>
                            )}
                        </div>
                    </div>
                    <div className="modal-mini__row">
                        <Select
                            defaultValue={values.type}
                            onChange={(value:string)=>{
                                setFieldValue('type',value)
                            }}

                            items={[
                            {
                                name: "Фіксовані",
                                value: "fix",
                            },
                            {
                                name: "Не Фіксовані",
                                value: "no_fix",
                            }
                        ]} />
                    </div>
                <div className="modal-mini__input">
                    <RectButton type={'button'} name={'Сохранить'} />
                </div>
                </form>
            </ModalMini>

            <div className="main-admin__header">
            <h1 style={{color:"#ffffff",marginBottom:"20px"}}>Баланс водителей</h1>

            </div>
            <div className="main-admin__filters">
                <div className={'main-admin__buttons'}>

                     <RectButton type={'htmlLink'} onClick={changePercentage}  className={'btn-rect-gradient-long'} name={'Глобальные проценты'} />
                </div>
            </div>
            <BalanceDriversContainer/>
        </div>
    );
};

export default BalancesPage;