// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.participant-list {
  position: relative;
}
.participant-list__item {
  margin-bottom: 10px;
}
.participant-list__pagination {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 30px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/BalanceList/BalanceList.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;AAEE;EACE,mBAAA;AAAJ;AAEE;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,cAAA;AAAJ","sourcesContent":["@import '../../../styles/_variables';\r\n\r\n.participant-list{\r\n  position: relative;\r\n  &__item{\r\n    margin-bottom: 10px;\r\n  }\r\n  &__pagination{\r\n    position: relative;\r\n    width: 100%;\r\n    display: flex;\r\n    justify-content: center;\r\n    margin: 30px 0;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
