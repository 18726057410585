import {createSlice} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";

import {
    asyncBalanceDriverAction,
    asyncGetBalanceDriversAction,
    asyncGetGlobalPercentAction, asyncSaveGlobalPercentAction
} from "./balanceDriversAction";


interface driversState {
    balanceDrivers: any[] ;
    totalBalanceDrivers:number;
    editMode: boolean;
    currentPage: number;
    selectedCount: number;
    selectDate:any;
    globalPercents: any;
    isDateModal: boolean;
    currentUserID: string;
    driverModalPercent: boolean;
    isLoading: boolean;
    error: string
}

const initialState: driversState = {
    balanceDrivers: [],
    totalBalanceDrivers:0,
    selectDate:null,
    editMode: false,
    globalPercents: null,
    currentUserID: '',
    driverModalPercent: false,
    currentPage: 1,
    selectedCount: 0,
    isDateModal: false,
    isLoading: false,
    error: ''
};


const balanceDriversSlice = createSlice({
    name:'balanceDriversSlice',
    initialState,
    reducers:{
        changeSelectDate(state,action){
            state.selectDate = action.payload
        },
        changeDateModal(state,action){
          state.isDateModal = action.payload
        },
        setCurrentPageDrivers(state,action){
            state.currentPage = action.payload;
        },
        changeDriverModalPercent(state,action){
            state.driverModalPercent = action.payload;
        },
        changeCurrentUserID(state,action){
            state.currentUserID = action.payload;
        },

        resetParticipants(state){
            state = initialState;
        }
    },
    extraReducers: (builder:any) => {
        builder
        .addCase(asyncGetGlobalPercentAction.fulfilled, (state:any, action: PayloadAction<any>) => {
            // const {rows,count} = action.payload;
            state.isLoading = false;
            state.error = '';
            // state.totalDrivers = count;
            state.globalPercents =  action.payload;
            // state.participants = rows ? rows: [];
        })
        .addCase(asyncGetGlobalPercentAction.pending, (state:any, action: PayloadAction<any>) => {
            state.isLoading = true;
        })
        .addCase(asyncGetGlobalPercentAction.rejected, (state:any, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = action.payload
        })

        builder
            .addCase(asyncSaveGlobalPercentAction.fulfilled, (state:any, action: PayloadAction<any>) => {
                // const {rows,count} = action.payload;
                state.isLoading = false;
                state.error = '';
                // state.totalDrivers = count;
                state.globalPercents =  action.payload;
                // state.participants = rows ? rows: [];
            })

        builder
        .addCase(asyncGetBalanceDriversAction.fulfilled, (state:any, action: PayloadAction<any>) => {
           // const {rows,count} = action.payload;
            state.isLoading = false;
            state.error = '';
           // state.totalDrivers = count;
            state.balanceDrivers =  action.payload;
           // state.participants = rows ? rows: [];
        })
        .addCase(asyncGetBalanceDriversAction.pending, (state:any, action: PayloadAction<any>) => {
            state.isLoading = true;
        })
        .addCase(asyncGetBalanceDriversAction.rejected, (state:any, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = action.payload
        })

        builder
            .addCase(asyncBalanceDriverAction.fulfilled, (state:any, action: PayloadAction<any>) => {
                const {userID} = action.payload;
               // state.isLoading = false;
                state.error = '';
                // state.totalDrivers = count;
                state.balanceDrivers = state.balanceDrivers.map((driver:any) =>
                    driver.userID === userID ? { ...driver, transactions:[
                            action.payload,
                            ...driver.transactions,

                        ] } : driver
                );
                // state.participants = rows ? rows: [];
            })
            .addCase(asyncBalanceDriverAction.rejected, (state:any, action: PayloadAction<any>) => {
             //   state.isLoading = false;
                state.error = action.payload
            })


    }
});

export const {changeCurrentUserID,changeDriverModalPercent,changeSelectDate,changeDateModal,setCurrentPageDrivers,resetParticipants} = balanceDriversSlice.actions;
export default balanceDriversSlice.reducer;